<template>
    <v-container fill-height fluid class="pa-0 ma-0 mb-10" v-if="sectionItem">
        <v-row class="ma-0 pa-0">
            <v-img :src="sectionItem.image_url != null ? sectionItem.image_url : require('@/assets/images/no-image.jpg')"
                   width="100%" height="400"
                   max-height="400"
                   position="top"
                   class="mx-0 align-end"
                   transition="slide-x-transition"
                   gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h2 white--text">
                               {{ sectionItem.title }}
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row align="start" justify="center" class="ma-0 pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12">
                <div class="container--fluid fill-height mt-0 pt-0 mx-0">
                    <v-timeline>
                        <v-timeline-item hide-dot
                                         v-for="(item, i) in sectionItem.content_pages"
                                         :key="i"
                                         color="orange"
                                         small
                                         class="align-center py-0">
                            <template v-slot:opposite class="py-1">
                                <v-img
                                    :src="item.image_url.length>0 ? item.image_url : require('@/assets/images/no-image.jpg')"
                                    height="auto"
                                    max-height="400"
                                    width="auto"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    aspect-ratio="1"
                                    position="top"
                                    class="align-end mt-0 ml-0">
                                </v-img>
                            </template>
                            <div class="mx-5 py-0">
                                <span
                                    class="font-raleway text-h5 text-md-h4 font-weight-regular text-uppercase"
                                    v-if="item.title !== null">
                                        {{ item.title }}
                                </span>
                                <v-responsive class="pt-5"
                                              :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'"
                                              v-if="item.content !== null">
                                    <span class="font-lato text-body-1 black--text"
                                          v-html="formatText(item.content)">
                                    </span>
                                </v-responsive>
                            </div>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </v-col>
        </v-row>
        <v-row align="start" justify="center" class="ma-0 pa-0" v-else>
            <v-col cols="12">
                <div class="container--fluid fill-height mt-0 pt-0 mx-0">
                    <div v-for="(item, i) in sectionItem.content_pages"
                         :key="i">
                        <v-row>
                            <v-col cols="12">
                                <div class="mx-5 pt-10 mb-5">
                                    <span
                                        class="font-raleway text-h5 text-md-h4 font-weight-regular text-uppercase">
                                            {{ item.title }}
                                    </span>
                                    <v-responsive class="pt-5" v-if="item.content"
                                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                    <span class="font-lato text-body-1 black--text"
                                          v-html="formatText(item.content)">
                                    </span>
                                    </v-responsive>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-img
                                    :src="item.image_url.length>0 ? item.image_url : require('@/assets/images/no-image.jpg')"
                                    height="auto"
                                    max-height="400"
                                    width="auto"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                    aspect-ratio="1"
                                    position="top"
                                    class="align-end mt-0 ml-0">
                                </v-img>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import "@fontsource/raleway";
    import "@fontsource/lato";
    import financePageService from "@/providers/FinancePageService";

    export default {
        name: "FinancePage",
        title: "Derivados Financieros | Private",
        data: () => ({
            sectionItem: {
                id: 0,
                title: "",
                sub_title: "",
                image_url: "",
                content_pages: []
            },
        }),
        methods: {
            formatText(text){
                let arr = [];
                // text.split('\n').forEach(item=>arr.push(`<p>${item.trim()}</p>`));
                text.split('\n').forEach(item=>arr.push(`<span>${item.trim()}</span>`));
                return arr.join('<br>');
            },
            getRecords() {
                financePageService.getViewData().then(record => {
                    // console.log('Record: ', record.value);
                    if (record.value !== null) {
                        this.sectionItem = record.value;
                    } else {
                        this.$router.push({ name: "EmptyPage" });
                    }
                });
            }
        },
        mounted() {
            this.sectionItems = null;
            this.getRecords();
        }
    }
</script>

<style scoped>
    .font-raleway {
        font-family: "Raleway", sans-serif !important;
    }
    .font-lato {
        font-family: "Lato", sans-serif !important;
    }
    .bottom-gradient {
        background-image: linear-gradient(
            to bottom,
            rgba(114, 217, 235, 0.185) 0%,
            rgba(255, 255, 255, 0.356) 100%
        );
    }
    .panel-filter-color {
        background: rgb(176, 214, 243, 0.3) 100%;
    }

    .v-timeline::before {
        top: 55px;
        height: 0; /*calc(100% - 110px)*/
    }
</style>

<style scoped lang="sass">
    @import '~vuetify/src/styles/generic/reset'

    $timeline-line-width: 10px,
    $timeline-divider-width: 10px
</style>